<template>
    <div class=" h-100  pt-2">
        <!-- <div v-if="ajax_call_in_progress" class="d-flex align-items-center justify-content-center">
            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
        </div> -->
        <div class="start-overflow">
            <div class="create-company-container w-100 " v-if="permissionProduct">
                <div style="width:100%;" class="mb-5" >
                    <div class="page-layout carded left-sidebar"
                        style="border: 0px solid rgb(148, 190, 240) !important;border-radius: 0px !important;">
                        <div class="page-content-wrapper" style="overflow-x:auto">
                            <div class="page-content">
                                <div class="demo-content create-company-container w-100">
                                    <div v-if="$route.path.startsWith('/employee/createemployee')" style="background-color: #00448b;color:#fff;text-align:center;padding:12px;text-transform:uppercase;border-radius: 10px 10px 0px 0px;">Set Product Permissions</div>
                                    <div v-else style="background-color: #00448b;color:#fff;text-align:center;padding:12px;text-transform:uppercase;border-radius: 10px 10px 0px 0px;">Subscribed Products</div>
                                    <table class="table table-new-template table-brand" style="border:1px solid grey">
                                        <thead class="text-left">
                                            <tr> 
                                                <th></th>
                                                <th>PRODUCTS</th>
                                                
                                                <th class="text-center">PERMISSION</th>
                                                <th >PRODUCT ACCESS</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            <!-- <tr v-if="ajax_call_in_progress" >
                                                <td colspan="5" class="text-center">
                                                    <div class="d-flex align-items-center justify-content-center w-100">
                                                        <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                                    </div>
                                                </td>
                                            </tr> -->
                                            <tr v-if="!ajax_call_in_progress && products_data.length == 0" class="text-center font-weight-bold">
                                                <td colspan="5" class="text-center">No Producs Found</td>
                                            </tr>
                                            <tr v-else v-for="(product, index) in products_data" :key="index" class="sent-call-tr" >
                                                <template v-if="product.product_name != 'BoardX'">
                                                    <td></td>
                                                    <td>{{product.product_name}}</td>
                                                    <td  > 
                                                        <div class="w-100" >
                                                            <div class="ml-auto mr-auto" style="position: relative; justify-content: left;width: 220px;">
                                                                <div v-if="product.has_access" class="d-flex align-items-center" style="margin-top: .7rem !important;">
                                                                    <multiselect class="diginew-multiselect input-field-underline item" name="product_manager" :searchable="true"
                                                                        :options="purchase_manager_type" v-validate="{required:true}" :name="'product_manager'+index" placeholder="select permission"
                                                                        :disabled="loggedInUser.user_role == 'employee' || (loggedInUser.user_role == 'admin' && (loggedInUser.company_id != $route.params.company_id)) || (comp_data.industry_type == 'Manufacturing' && product.product_name == 'DigiPOS')" 
                                                                        label="name" @open="getProductRoles(product.product_id, index)" :loading="product.product_roles_loading" :show-labels="false" v-model="product.role_id"
                                                                    >
                                                                        <template slot="noOptions">
                                                                            <div class="text-secondary text-center fw-600">Manager List is not found</div>
                                                                        </template>
                                                                        <template slot="noResult">
                                                                            <div class="text-secondary text-center fw-600">No Results Found</div>
                                                                        </template>
                                                                    </multiselect>
                                                                </div>
                                                                <div v-else class="d-flex align-items-center" style="margin-top: .7rem !important;">
                                                                    <multiselect class="diginew-multiselect input-field-underline item" name="product_manager" :searchable="true"
                                                                        :options="purchase_manager_type" placeholder="select permission"
                                                                        :disabled="loggedInUser.user_role == 'employee' || (loggedInUser.user_role == 'admin' && (loggedInUser.company_id != $route.params.company_id)) || (comp_data.industry_type == 'Manufacturing' && product.product_name == 'DigiPOS')" 
                                                                        label="name" @open="getProductRoles(product.product_id, index)" :loading="product.product_roles_loading" :show-labels="false" v-model="product.role_id"
                                                                    >
                                                                        <template slot="noOptions">
                                                                            <div class="text-secondary text-center fw-600">Manager List is not found</div>
                                                                        </template>
                                                                        <template slot="noResult">
                                                                            <div class="text-secondary text-center fw-600">No Results Found</div>
                                                                        </template>
                                                                    </multiselect>
                                                                </div>
                                                                <div v-if="$route.path.startsWith('/consultants/editconsultant/cmpid/')">
                                                                    <span class="invalid-feedback-form text-danger" v-if="product.role_id == null && product.has_access">
                                                                    Please Select Permission
                                                                    </span>
                                                                </div>
                                                                <div v-else>
                                                                    <span class="invalid-feedback-form text-danger" v-if="product.role_id.id == '' && product.has_access">
                                                                    Please Select Permission
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>  
                                                    </td>
                                                    <td >
                                                        <span class="switch switch-lg mr-2" style="top: 4px;">
                                                            <el-switch 
                                                                :disabled="loggedInUser.user_role == 'employee' || (loggedInUser.user_role == 'admin' && (loggedInUser.company_id != $route.params.company_id)) || (comp_data.industry_type == 'Manufacturing' && product.product_name == 'DigiPOS')"
                                                                active-color="#00448b"
                                                                inactive-color="#bcb8b8"
                                                                v-model ="product.has_access"
                                                                class="routers">
                                                            </el-switch> 
                                                        </span>
                                                    </td>
                                                    <td></td>
                                                </template>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="mt-9 mb-9">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <button class="btn btn-outline-secondary" :disabled="save_create_btn_hide" @click="cancel" style="width: 120px;border-radius: 5px;">Cancel</button>
                                            <button class="btn ml-3 text-white" style="background-color: #ff9a35 !important;width: 120px; border-radius: 5px !important;" @click="backToScreen" >Back</button>
                                            <button class="btn btn-new-success ml-5" :disabled="save_create_btn_hide" @click="save" style="width: 120px;border-radius: 5px;">Save
                                                <hollow-dots-spinner v-if="save_create_btn_hide" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                                            </button>
                                        </div>
                                    </div>
                                    <div v-else-if="loggedInUser.user_role == 'admin' && (loggedInUser.company_id == $route.params.company_id)" class="mt-9 mb-9">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <button class="btn btn-new-cancel" @click="cancel" :disabled="save_create_btn_hide">Cancel</button>
                                            <button class="btn ml-3" style="background-color: #ff9a35 !important; " :disabled="save_create_btn_hide" @click="backToScreen" >Back</button>
                                            <button class="btn btn-new-success ml-3" :disabled="save_create_btn_hide" @click="save" >Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-success v-if="create_success" @hideSuccess="hideSuccess" :success_message="success_message" modal_name="create-success"></create-success>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
    
</template>
<script>
import { HollowDotsSpinner } from "epic-spinners";
import companies from '../mixins/companies';
const CreateSuccess = () => import('./CreateSuccess');
import { SweetModal } from 'sweet-modal-vue';
import {
    HalfCircleSpinner
} from "epic-spinners";
import product from '../mixins/product';
import consultant from '../mixins/consultant';
import globals from '../globals';
import axios from 'axios';
    export default {
        mixins: [companies, product, consultant],
        props: ["consultant_data", "comp_data"],
        data() {
            return {
                permissionProduct: true,
                warning_msg: '',
                success_msg: '',
                permissionProductTab: true,
                purchase_manager_type: [],
                purchase_manager:'',
                productAccess:true,
                productAccountAccess: false,
                skip: 0,
                limit: 10,
                search_key: '',
                products_data: [],
                product_roles_loading: false,
                ajax_call_in_progress: false,
                create_success: false,
                success_message: "",
                product_index: 0,
                save_create_btn_hide: false,
                validation_permission: false
            }
        },
        components: {
            HalfCircleSpinner,
            CreateSuccess,
            SweetModal,
            HollowDotsSpinner
        },
        methods: {
            hideSuccess() {
                this.create_success = false;
                this.$router.push('/consultants/listview/cmpid/'+this.$route.params.company_id);
            },
            async getSubscribedProducts() {
                this.ajax_call_in_progress = true;
                try {
                    let params = {
                        company_id: this.$route.params.company_id,
                        search_key: this.search_key
                    };
                    let response = await this.getCompaniesSubscribedProducts(params);
                    if(response.status_id == 1) {
                        let prod_data = response.products_list;
                        prod_data.forEach((data, index) => {
                            this.products_data.push({
                                has_access: false,
                                product_id: data.id,
                                product_name: data.product_code,
                                role_id: {
                                    id: "",
                                    name: "",
                                },
                                product_roles_loading: false
                            })
                        })
                    }
                    this.ajax_call_in_progress = false;
                }
                catch(err) {
                    console.log(err);
                }
            },
            async getProductRoles(id, index) {
                this.purchase_manager_type = []
                this.product_index = index
                this.products_data[this.product_index].product_roles_loading = true;
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        search_key: this.search_key,
                        product_id: id,
                    };
                    let response = await this.getListProductRole(params);
                    if(response.status_id == 1) {
                        this.purchase_manager_type = response.response;
                    }
                    this.products_data[this.product_index].product_roles_loading = false;
                }
                catch(err) {
                    console.log(err);
                    this.products_data[this.product_index].product_roles_loading = false;
                }
            },
            cancel() {
                this.$router.push('/consultants/listview/cmpid/'+this.$route.params.company_id);
                // this.$emit('switchWizard1');
            },
            backToScreen() {
                this.$emit('switchWizard1');
            },
            async save() {
                try {
                    this.save_create_btn_hide = true;
                    this.validation_permission = false
                    if(this.$route.path.startsWith('/consultants/editconsultant/cmpid/')){
                        this.validation_permission = false
                        // this.products_data.forEach((x, data_index) => {
                        //     if(x.has_access == true && x.role_id == null){
                        //         this.validation_permission = true
                        //         this.save_create_btn_hide = false;  
                        //         console.log(this.save_create_btn_hide)
                        //     }
                        // })
                    }else{
                        this.products_data.forEach((x, data_index) => {
                            if(x.has_access == true && x.role_id.id == ''){
                                this.validation_permission = true
                                this.save_create_btn_hide = false;  
                                console.log(this.save_create_btn_hide)
                            }
                        })
                    }
                    if(this.validation_permission == false){
                        if(this.$route.path.startsWith('/consultants/editconsultant/cmpid/')) {
                            this.save_create_btn_hide = true;
                            let params = JSON.parse(localStorage.getItem("consultant_data"));
                            params.products = this.products_data;
                            params.products.forEach((data, index) => {
                                if(data.id == '') {
                                    delete data.id
                                }
                                if(data.hasOwnProperty('product_roles_loading')) {
                                    delete data.product_roles_loading
                                }
                                //delete data.product_name
                                data.role_id = data.role_id.id
                            })
                            await axios.patch(globals.AUTH_SERVICE + `/consultancy/${this.$route.params.id}`, params).then(response => {
                                if(response.data.status_id == 1) {
                                    this.success_message = response.data.message;
                                    this.create_success = true;
                                    setTimeout(() => {
                                        this.$modal.show('create-success');
                                    }, 500);
                                }
                            }).catch((err) => {
                                this.save_create_btn_hide = false;
                                this.warning_msg = err.response.data.reason;
                                this.$refs.warning_modal.open();
                            });
                        } else {
                            let params = JSON.parse(localStorage.getItem("consultant_data"));
                            this.products_data.forEach((data, index) => {
                            // delete data.product_name
                                if(data.hasOwnProperty('product_roles_loading')) {
                                    delete data.product_roles_loading
                                }
                                if(data.role_id.id == '') {
                                    
                                } else {
                                    params.products.push({
                                        has_access: data.has_access,
                                        product_id: data.product_id,
                                        role_id: data.role_id.id
                                    });
                                }
                            })
                            await axios.post(globals.AUTH_SERVICE + `/consultancy/`, params).then((response) => {
                                if(response.data.status_id == 1) {
                                    localStorage.removeItem("consultant_data");
                                    this.success_message = response.data.message;
                                    this.create_success = true;
                                    setTimeout(() => {
                                        this.$modal.show('create-success');
                                    }, 500);
                                }
                            }).catch((err) => {
                                this.save_create_btn_hide = false;
                                this.warning_msg = err.response.data.reason;
                                this.$refs.warning_modal.open();
                            });
                        }
                    }
                }
                catch(err) {
                    this.save_create_btn_hide = false;
                    this.warning_msg = err.response.data.reason;
                    this.$refs.warning_modal.open();
                }
            }
        },
        mounted() {
            if(this.$route.path.startsWith('/consultants/editconsultant/cmpid')) {
                let prod_data = this.consultant_data.products;
                prod_data.forEach((data, index) => {
                    this.products_data.push({
                        has_access: data.has_access,
                        id: data.id ? data.id : "",
                        product_id: data.product_id,
                        product_name: data.product_name,
                        role_id: {
                            id: data.role_id,
                            name: data.role_name,
                        },
                        product_roles_loading: false
                    })
                })
            } else {
                this.getSubscribedProducts();
            }
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
        }
    }
</script>
<style >
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
    .create-company-container {
        background-color: #fff;
        padding: 15.5px 0 0px 0;
        border-radius: 6px;
        /*border: 1px solid grey;*/
    }
    .overflow-auto {
        overflow: auto;
    }
    .table-new-template>tbody>tr:nth-of-type(even) td {
        background-color: #dfe8f4;
        padding:10px;
    }
    .table-new-template>tbody>tr:nth-of-type(odd) td {
        background-color: #fff;
        padding:10px;
        
    }
    
    .table-new-template>tbody>tr:nth-of-type(even) td div >.diginew-multiselect,
    .table-new-template>tbody>tr:nth-of-type(even) td div >.diginew-multiselect> .multiselect__tags{
        background: #dfe8f4!important;
    }
    
    .table-new-template>tbody>tr:nth-of-type(odd) td div >.diginew-multiselect,
    .table-new-template>tbody>tr:nth-of-type(odd) td div >.diginew-multiselect> .multiselect__tags{
        background: #fff!important;
    }
   
    .table-new-template thead tr th {
        vertical-align: middle;
        color: #00448b;
        text-transform: capitalize;
        font-weight: 500;
        letter-spacing: 1px;
        white-space: nowrap;
        font-size: 14px;
        text-align: left
    }

    .table-new-template thead th, 
    .table-new-template tbody td{
        align-items: center;
        padding: 10px !important;;

    }
    
    
    .table-new-template tbody tr,
    .table-new-template tbody td {
        align-items: center;
        font-weight: 600;
        text-align: left
    }
    .placeholder {
        color: #000!important;   
    }
    .table-new-template>tbody>tr> td div >.diginew-multiselect> .multiselect__tags > .multiselect__placeholder {
        color: #000;
    }
    .btn-new-cancel{
        border-radius: 5px!important;
        background-color: #d51650;
        padding-left: 25px !important;
        padding-right: 25px !important;
        color:#fff!important
    }
    .table-new-template>tbody>tr:nth-of-type(odd) td div >.diginew-multiselect> .multiselect__tags > .multiselect__input, .multiselect__single{
        background-color:#fff
    }
    .table-new-template>tbody>tr:nth-of-type(even) td div >.diginew-multiselect> .multiselect__tags > .multiselect__input, .multiselect__single{
        background: #dfe8f4!important;
    }
    .multiselect__input{
       margin-bottom :0px!important;
   }
   .multiselect__placeholder{
       padding:0px!important;
   }
</style>